// extracted by mini-css-extract-plugin
export var mobile = "766px";
export var tablet = "1025px";
export var desktop = "1368px";
export var blue300 = "#0929ae";
export var violet300 = "#4f2c98";
export var blue200 = "#1b3bc0";
export var pink200 = "#ef5b72";
export var projectsBanner = "Projects-module--projectsBanner--3xcyw";
export var projectsContainer = "Projects-module--projectsContainer--2UbmO";
export var projectPreview = "Projects-module--projectPreview--2a1F6";
export var projectLink = "Projects-module--projectLink--2rEAX";
export var projectTitle = "Projects-module--projectTitle--3-mcJ";
export var button = "Projects-module--button--3bdrl";