import React, { useState } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { Banner } from "src/components/Banner";
import { Button } from "src/components/Button";
import { Container } from "src/components/Container";
import { PageWrapper } from "src/components/PageWrapper";
import { Typography } from "src/components/Typography";
import { violet300 } from "src/styles/variables.module.scss";

import { ProjectItem } from "./ProjectItem";
import * as styles from "./Projects.module.scss";

const query = graphql`
  query {
    allStrapiProjects {
      edges {
        node {
          title
          slug
          description
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;

const ITEMS_COUNT = 6;

export const Projects: React.FC = () => {
  const data = useStaticQuery(query);
  const projects = data.allStrapiProjects.edges;
  const [showedItems, setShowedItems] = useState(ITEMS_COUNT);

  const handleShowMoreClick = () => {
    setShowedItems((prev) => prev + ITEMS_COUNT);
  };

  return (
    <PageWrapper topAutoScroll title="Продукты">
      <div>
        <Banner className={styles.projectsBanner} backgroundColor={violet300}>
          <Container>
            <Typography color="white" variant="h3">
              Продукты
            </Typography>
          </Container>
        </Banner>
      </div>
      <div className={styles.projectsContainer}>
        {projects.slice(0, showedItems).map(({ node }) => (
          <ProjectItem key={node.slug} {...node} />
        ))}
      </div>
      {showedItems < projects.length && (
        <Button
          color="green"
          className={styles.button}
          onClick={handleShowMoreClick}
        >
          показать еще
        </Button>
      )}
    </PageWrapper>
  );
};
