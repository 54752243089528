import React from "react";

import { Link } from "gatsby";
import { Typography } from "src/components/Typography";
import { PathEnum } from "src/enums/PathEnum";

import * as styles from "./Projects.module.scss";

export interface ShortProject {
  title: string;
  description: string;
  slug: string;
  image: {
    width: number;
    height: number;
    localFile: {
      publicURL: string;
    };
  };
}

export const ProjectItem: React.FC<ShortProject> = ({
  title,
  description,
  slug,
  image,
}) => {
  return (
    <Link className={styles.projectLink} to={`${PathEnum.Projects}/${slug}`}>
      <img
        className={styles.projectPreview}
        width={image.width}
        height={image.height}
        src={image.localFile.publicURL}
      />
      <Typography className={styles.projectTitle} variant="h5">
        {title}
      </Typography>
      {description && <Typography variant="p">{description}</Typography>}
    </Link>
  );
};
